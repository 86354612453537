import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import flatten from 'lodash/flatten'
import flattenDeep from 'lodash/flattenDeep'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import ImageWithBoundingBoxes from '../components/common/ImageWithBoundingBox'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { consultationsSelector, ConsultationsState } from '../hasura/slices/consultations'
import { medicalImagesForConditionAction, medicalImagesSelector, MedicalImagesState } from '../hasura/slices/medical-images'
import { keyForAwsS3Url, numberWithCommas, Option, searchQueryParams } from '../lib/helpers'
import { parseBoundingBoxDescription } from './tagging/BoundingBoxCanvas'
import { riskForSeverity } from '../lib/aiHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

const MAX_IMAGES_TO_SHOW = 300

export default function Condition() {
  const dispatch = useDispatch()

  const [view, setView] = useState<Option | null>(null)
  const [specialist, setSpecialist] = useState<Option | null>(null)
  const [selectedKeyValueOptions, setSelectedKeyValueOptions] = useState<Option[]>([])

  const id = parseInt(searchQueryParams('id') || '', 10)

  const { accessToken }: UsersState = useSelector(usersSelector)
  const { medicalImagesForCondition }: MedicalImagesState = useSelector(medicalImagesSelector)
  const { presignedCaseImageUrls, conditions }: ConsultationsState = useSelector(consultationsSelector)

  const images = medicalImagesForCondition?.filter((m) => {
    const matchesView = !view || m.view?.display_name === view?.value
    const matchesSpecialist = !specialist || m.predictions_normalizeds.some((p) => p.user?.id === specialist?.value)
    const isPositive = m.predictions_normalizeds.some((p) => p.condition?.id === id && !p.display_name?.includes('NOT '))
    const matchesKeyValueOptions =
      !selectedKeyValueOptions.length ||
      selectedKeyValueOptions.every((k) =>
        m.predictions_normalizeds.some((p) => p.key_values_json && flatten(Object.values(p.key_values_json)).includes(k.value))
      )
    return isPositive && matchesSpecialist && matchesView && matchesKeyValueOptions
  })

  const condition = conditions.find((c) => c.id === id)
  const risk = condition?.severity ? riskForSeverity(condition.severity) : undefined
  const views = Object.keys(groupBy(images, 'view.display_name')).sort()
  const imagesCount = images?.length || 0
  const yoloLabel = condition?.ml_config?.yolo_label

  const viewOptions: Option[] = views.map((v) => ({ value: v, label: v }))

  const specialistOptions: Option[] = uniqBy(flatten(images?.map((i) => i.predictions_normalizeds.map((p) => p.user))), 'id').map(
    (u) => ({
      value: u!.id,
      label: u!.display_name,
    })
  )

  // @ts-ignore
  const keyValueOptions: Option[] = uniq(
    flattenDeep(
      images?.map((i) => i.predictions_normalizeds.filter((p) => p.key_values_json).map((p) => Object.values(p.key_values_json)))
    )
  )
    .sort()
    .map((value) => ({ value, label: value }))

  useEffect(() => {
    if (!accessToken || !id) return

    dispatch(medicalImagesForConditionAction(accessToken, id))
  }, [accessToken])

  return (
    <Layout>
      <MainBox defaultPadding>
        <p className="text-l m-0">
          <span className="bold mr-1">{condition?.display_name}</span>{' '}
          <span className="text--gray6 text-xs">
            {condition?.category?.display_name.toLowerCase()} | {risk ? `${risk.toLowerCase()} risk` : ''}
            {yoloLabel ? ` | ${yoloLabel}` : ''}
          </span>
        </p>

        <div className="d-flex gap-10px mt-1">
          <Select
            className="width-250px"
            classNamePrefix="react-select"
            isClearable
            onChange={(o: Option | null) => setView(o)}
            options={viewOptions}
            placeholder="Select view"
            value={view}
          />

          <Select
            className="width-250px"
            classNamePrefix="react-select"
            isClearable
            onChange={(o: Option | null) => setSpecialist(o)}
            options={specialistOptions}
            placeholder="Select specialist"
            value={specialist}
          />

          <Select
            // @ts-ignore
            onChange={(options: Option[]) => setSelectedKeyValueOptions(options)}
            className="width-250px"
            classNamePrefix="react-select"
            isClearable
            isMulti
            options={keyValueOptions}
            placeholder="Select option"
            value={selectedKeyValueOptions}
          />
        </div>

        {imagesCount > 0 ? (
          <p className="mb-0 mt-1 text-s">
            {Math.min(imagesCount, MAX_IMAGES_TO_SHOW)} images
            {imagesCount > MAX_IMAGES_TO_SHOW ? ` (out of ${numberWithCommas(imagesCount)})` : ''}
          </p>
        ) : (
          <Spinner size="sm" className="mt-1" />
        )}

        <div className="d-flex flex-wrap mt-3 gap-10px">
          {orderBy(images, ['id'], ['asc'])
            .slice(0, MAX_IMAGES_TO_SHOW)
            .map((i, idx) => {
              const src = presignedCaseImageUrls.find((p) => {
                if (yoloLabel && yoloLabel !== 'original') {
                  const url = i.medical_image_permutations.find((p) => p.label === yoloLabel)?.aws_s3_url
                  return p.includes(url || '')
                } else {
                  return i.aws_s3_url && p.includes(i.aws_s3_url)
                }
              })
              if (!src) return null

              const boundingBoxPredictions = i.predictions_normalizeds
                .filter((p) => p.bounding_box)
                .map((p) => ({
                  // @ts-ignore
                  prediction: Object.keys(p.key_values_json).map((k) => [k, p.key_values_json[k]]),
                  boundingBox: parseBoundingBoxDescription(p.bounding_box!),
                }))

              return (
                <div>
                  <div className="height-250px width-250px border border--gray1">
                    {boundingBoxPredictions.length > 0 ? (
                      // @ts-ignore
                      <ImageWithBoundingBoxes key={idx} src={src} predictions={boundingBoxPredictions.filter((b) => b.prediction)} />
                    ) : (
                      <img className="mh-100 mw-100 h-auto w-auto" src={src} />
                    )}
                  </div>
                  <p className="text-xs text--gray5 m-0">
                    {keyForAwsS3Url(src.split('?')[0])} |{' '}
                    {i.predictions_normalizeds
                      .filter((p) => p.condition?.id === id)
                      .map((p) => p.grade || p.issue || (p.display_name?.includes('NOT') ? 'no' : 'yes'))
                      .join(' / ')}
                  </p>
                </div>
              )
            })}
        </div>
      </MainBox>
    </Layout>
  )
}
